import { ReactElement } from 'react';
import { HeroSplit } from 'src/features/ui/templates/HeroSplit';
import { LoginPanel } from 'src/features/users/templates/LoginPanel';
import { LoginRefused } from 'src/services/LoginRefused';
import { ssrProvider } from '../services/ssrProvider';

export default function Page(): JSX.Element {
  return (
    <HeroSplit>
      <LoginPanel topClassName="mt-40 w-8/12" />
    </HeroSplit>
  );
}

Page.getLayout = function getLayout(page: ReactElement) {
  return <LoginRefused>{page}</LoginRefused>;
};

export const getServerSideProps = ssrProvider({});
