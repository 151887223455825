import { useMutation } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { withZodSchema } from 'formik-validator-zod';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Button } from 'src/features/ui/components/Button';
import { Input } from 'src/features/ui/components/Input';
import { InternalLink } from 'src/features/ui/components/InternalLink';
import { InternalField } from 'src/features/ui/forms/InternalField';
import endpointConfigs from 'src/services/api/endpointConfigs';
import routes from 'src/services/routeConfigs';
import { z } from 'zod';

const loginFormSchema = z.object({
  email: z.string().email(),
  password: z.string(),
});

type LoginPanelProps = {
  topClassName?: string;
};

export const LoginPanel = ({ topClassName }: LoginPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();

  const emailLoginMutation = useMutation({
    ...endpointConfigs.emailLogin,
    onSuccess: async () => {
      await router.push(routes.home);
    },
  });

  return (
    <div className={topClassName}>
      <div className="mx-auto mb-[60px] flex h-[60px] w-[180px] items-center justify-center border border-white">
        Logo
      </div>

      <div className="mb-10 text-2xl">{t('login.form.signin_prompt')}</div>
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={withZodSchema(loginFormSchema)}
        onSubmit={values => emailLoginMutation.mutate(values)}
      >
        <Form>
          <div className="mb-5 flex flex-col gap-y-11">
            <InternalField name="email" label={t('login.form.email')} as={Input} />
            <InternalField type="password" name="password" label={t('login.form.password')} as={Input} />
            <div className="flex w-full flex-row items-stretch gap-x-5">
              <Button type="submit" variant="secondary" className="grow" label={t('login.form.signin_with_email')} />
              <Button
                variant="primary"
                className="grow"
                label={t('login.form.signin_with_google')}
                url={routes.backSsoLogin}
              />
            </div>
          </div>
          <InternalLink href={routes.forgottenPassword}>
            <span className="text-xs">{t('login.form.forgot_password')}</span>
          </InternalLink>
        </Form>
      </Formik>
    </div>
  );
};
