type InternalLabelProps = {
  name: string;
  label: string;
  required?: boolean;
};

export const InternalLabel = ({ name, label, required = false }: InternalLabelProps): JSX.Element => (
  <div className="relative h-[0px] w-[0px]">
    <label htmlFor={name} className="absolute -mt-[1.5rem] whitespace-nowrap text-xs">
      {label}
      {required && <span className="text-danger"> *</span>}
    </label>
  </div>
);
