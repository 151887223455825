import { useTranslation, Trans } from 'next-i18next';
import { Button } from 'src/features/ui/components/Button';
import { InternalLink } from 'src/features/ui/components/InternalLink';

export type HeroSplitProps = {
  children: JSX.Element;
};

export const HeroSplit = ({ children }: HeroSplitProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="flex min-h-screen flex-row text-white">
      <div className="flex basis-5/12 justify-center bg-grey-1">{children}</div>

      <div className="flex basis-7/12 flex-col items-center justify-between bg-grey-3 bg-hero">
        <div className="mt-64 w-8/12">
          <div className="text-5xl leading-[70px]">{t('login.hero.main')}</div>

          <div className="mt-8 flex items-center justify-between text-xl">
            <span>{t('login.hero.catch')}</span> <Button variant="secondary" label={t('login.hero.call_to_action')} />
          </div>
        </div>

        <div className="mb-10 w-8/12">
          <Trans
            i18nKey="login.hero.signature"
            components={{ apptitude_link: <InternalLink href="https://apptitude.ch" /> }}
          />
        </div>
      </div>
    </div>
  );
};
