import { ErrorMessage, Field, FieldAttributes } from 'formik';
import { ReactNode } from 'react';
import { InternalLabel } from './InternalLabel';

type InternalFieldProps = {
  name: string;
  label: string;
  required?: boolean;
  className?: string;
  children?: ReactNode;
} & FieldAttributes<unknown>;

export const InternalField = ({
  name,
  label,
  required = true,
  className,
  children,
  ...fieldAttrs
}: InternalFieldProps): JSX.Element => (
  <div className={className}>
    <InternalLabel name={name} label={label} required={required} />
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Field name={name} {...fieldAttrs}>
      {children}
    </Field>
    <ErrorMessage name={name}>{msg => <span className="text-xs text-danger">{msg}</span>}</ErrorMessage>
  </div>
);
