import classNames from 'classnames';
import _debounce from 'lodash/debounce';
import { forwardRef, useEffect, useMemo } from 'react';
import { Icon, IconProps, IconType } from './Icon';

// Styles

type StyleDef = {
  icon: string;
  input: string;
};

const styleDefs = {
  default: {
    icon: 'h-5, text-grey-1',
    input: 'h-10 text-sm rounded-lg',
  },
} as const satisfies Record<string, StyleDef>;

export type InputVariant = keyof typeof styleDefs;

// Props type

export type InputProps = {
  /**
   * Set the debounce time for this input.
   * Has no effect without onDebounce function.
   */
  debounceTime?: number;
  /**
   * Called only after `debounceTime` ms when there is no more user input
   * Be careful to use a useCallback for your function or debounce will not work properly
   */
  onDebounce?(value: string): void;
  disabled?: boolean;
  error?: boolean;
  iconLeft?: IconType | IconProps;
  iconRight?: IconType | IconProps;
  placeholder?: string;
  readonly?: boolean;
  value?: string;
  type: 'text' | 'email' | 'password' | 'number';
  variant?: InputVariant;
} & Pick<
  JSX.IntrinsicElements['input'],
  'autoFocus' | 'name' | 'required' | 'onChange' | 'onBlur' | 'pattern' | 'className' | 'onFocus' | 'defaultValue'
>;

// Input

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      debounceTime,
      disabled,
      error,
      iconLeft,
      iconRight,
      onDebounce,
      defaultValue,
      value,
      variant = 'default',
      ...props
    },
    ref,
  ) => {
    const style = styleDefs[variant];

    const iconLeftProps = getIconProps(iconLeft);
    const iconRightProps = getIconProps(iconRight);

    // Not a useCallback as `debounce` can be falsy
    const debounce = useMemo(
      () => (debounceTime || 0) > 0 && onDebounce && _debounce(onDebounce, debounceTime),
      [debounceTime, onDebounce],
    );

    useEffect(
      () => () => {
        // Cancels debounce on each change
        if (debounce) {
          debounce.cancel();
        }
      },
      [debounce],
    );

    return (
      <div className={classNames('relative flex w-full', props.className)}>
        {iconLeftProps && (
          <div
            className={classNames(
              'absolute inset-y-0 left-0 flex items-center pl-4',
              { 'pointer-events-none': typeof iconLeftProps === 'string' || iconLeftProps.onClick == null },
              style.icon,
            )}
          >
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Icon {...iconLeftProps} />
          </div>
        )}
        <input
          id={props.name}
          type={type}
          name={props.name}
          className={classNames(
            'block w-full bg-white px-3 text-sm text-grey-1 ring-1 ring-inset ring-grey-3',
            'placeholder:text-gray-5',
            'focus:outline-none focus:ring-2 focus:ring-primary ',
            'disabled:cursor-not-allowed disabled:text-grey-5',
            {
              'pl-12': iconLeft,
              'pr-12': iconRight || error,
              'border-red-1 border': error,
            },
            style.input,
          )}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={props.autoFocus}
          placeholder={props.placeholder}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={event => {
            if (props.onChange) {
              props.onChange(event);
            }
            if (debounce) {
              debounce(event.target.value);
            }
          }}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          pattern={props.pattern}
          readOnly={props.readonly}
          required={props.required}
          value={value}
          ref={ref}
        />
        {iconRightProps && (
          <div
            className={classNames(
              'absolute inset-y-0 right-0 flex items-center pr-4',
              { 'pointer-events-none': typeof iconRightProps === 'string' || iconRightProps.onClick == null },
              style.icon,
            )}
          >
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Icon {...iconRightProps} />
          </div>
        )}
      </div>
    );
  },
);

/**
 * @return the final icon props from the input icon props
 */
function getIconProps(icon: InputProps['iconLeft']) {
  return icon && (typeof icon === 'string' ? { icon } : icon);
}
