import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Spinner } from 'src/features/ui/components/Spinner';
import endpointConfigs from 'src/services/api/endpointConfigs';
import routeConfigs from './routeConfigs';

export type LoginRefusedProps = { children: JSX.Element };

export const LoginRefused = ({ children }: LoginRefusedProps): JSX.Element => {
  const router = useRouter();

  const [userIsNotLoggedIn, setUserIsNotLoggedIn] = useState(false);

  useQuery({
    ...endpointConfigs.currentUser,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onSuccess: async () => {
      await router.push(routeConfigs.home);
    },
    onError: () => {
      setUserIsNotLoggedIn(true);
    },
    retry: (_failureCount, error: AxiosError) => {
      if (error.response?.status === 403) {
        return false;
      }
      return true;
    },
  });

  return userIsNotLoggedIn ? children : <Spinner fullScreen />;
};
